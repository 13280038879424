export const CONTRACTS_BATCH_DELETE_REQUEST = 'CONTRACTS_BATCH_DELETE_REQUEST'
export const CONTRACTS_BATCH_DELETE_SUCCESS = 'CONTRACTS_BATCH_DELETE_SUCCESS'
export const CONTRACTS_BATCH_DELETE_FAILURE = 'CONTRACTS_BATCH_DELETE_FAILURE'

export const CONTRACTS_FETCH_MANY_REQUEST = 'CONTRACTS_FETCH_MANY_REQUEST'
export const CONTRACTS_FETCH_MANY_SUCCESS = 'CONTRACTS_FETCH_MANY_SUCCESS'
export const CONTRACTS_FETCH_MANY_FAILURE = 'CONTRACTS_FETCH_MANY_FAILURE'

export const CONTRACTS_UPLOAD_REQUEST = 'CONTRACTS_UPLOAD_REQUEST'
export const CONTRACTS_UPLOAD_SUCCESS = 'CONTRACTS_UPLOAD_SUCCESS'
export const CONTRACTS_UPLOAD_FAILURE = 'CONTRACTS_UPLOAD_FAILURE'

export const CONTRACTS_FETCH_REQUEST = 'CONTRACTS_FETCH_REQUEST'
export const CONTRACTS_FETCH_SUCCESS = 'CONTRACTS_FETCH_SUCCESS'
export const CONTRACTS_FETCH_FAILURE = 'CONTRACTS_FETCH_FAILURE'

export const CONTRACTS_SAVE_REQUEST = 'CONTRACTS_SAVE_REQUEST'
export const CONTRACTS_SAVE_SUCCESS = 'CONTRACTS_SAVE_SUCCESS'
export const CONTRACTS_SAVE_FAILURE = 'CONTRACTS_SAVE_FAILURE'
