<template>
  <router-view />
</template>

<script>
import energyCustomersModule from '../../store/energy-customers'
import energySitesModule from '../../store/energy-sites'
import contractsModule from '../../store/contracts'
import suppliersModule from '../../store/suppliers'
import metersModule from '../../store/meters'
import usersModule from '../../store/users'

export default {
  beforeCreate () {
    if (!this.$store.hasModule('energy-customers')) {
      this.$store.registerModule('energy-customers', energyCustomersModule)
    } if (!this.$store.hasModule('energy-sites')) {
      this.$store.registerModule('energy-sites', energySitesModule)
    } if (!this.$store.hasModule('contracts')) {
      this.$store.registerModule('contracts', contractsModule)
    } if (!this.$store.hasModule('suppliers')) {
      this.$store.registerModule('suppliers', suppliersModule)
    } if (!this.$store.hasModule('meters')) {
      this.$store.registerModule('meters', metersModule)
    } if (!this.$store.hasModule('users')) {
      this.$store.registerModule('users', usersModule)
    }
  },
  destroyed () {
    if (this.$store.hasModule('energy-customers')) {
      this.$store.unregisterModule('energy-customers')
    } if (!this.$store.hasModule('energy-sites')) {
      this.$store.unregisterModule('energy-sites')
    } if (!this.$store.hasModule('contracts')) {
      this.$store.unregisterModule('contracts')
    } if (!this.$store.hasModule('suppliers')) {
      this.$store.unregisterModule('suppliers')
    } if (!this.$store.hasModule('meters')) {
      this.$store.unregisterModule('meters')
    } if (!this.$store.hasModule('users')) {
      this.$store.unregisterModule('users')
    }
  }
}
</script>
