import {
  CONTRACTS_BATCH_DELETE_REQUEST, CONTRACTS_BATCH_DELETE_SUCCESS, CONTRACTS_BATCH_DELETE_FAILURE,
  CONTRACTS_FETCH_MANY_REQUEST, CONTRACTS_FETCH_MANY_SUCCESS, CONTRACTS_FETCH_MANY_FAILURE,
  CONTRACTS_UPLOAD_REQUEST, CONTRACTS_UPLOAD_SUCCESS, CONTRACTS_UPLOAD_FAILURE,
  CONTRACTS_FETCH_REQUEST, CONTRACTS_FETCH_SUCCESS, CONTRACTS_FETCH_FAILURE,
  CONTRACTS_SAVE_REQUEST, CONTRACTS_SAVE_SUCCESS, CONTRACTS_SAVE_FAILURE
} from './types'

export default {
  reset ({ commit }) {
    commit(CONTRACTS_FETCH_SUCCESS, { data: {} })
  },
  deleteManyContracts ({ commit }, { ids = [] }) {
    commit(CONTRACTS_BATCH_DELETE_REQUEST)
    return window.axios.post('/contracts', { _method: 'DELETE', ids }).then(response => {
      commit(CONTRACTS_BATCH_DELETE_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CONTRACTS_BATCH_DELETE_FAILURE, error)
      throw error
    })
  },
  fetchManyContracts ({ commit }, { query = '', page = 1 }) {
    commit(CONTRACTS_FETCH_MANY_REQUEST)
    return window.axios.get('/contracts', { params: { query, page } }).then(response => {
      commit(CONTRACTS_FETCH_MANY_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CONTRACTS_FETCH_MANY_FAILURE, error)
      throw error
    })
  },
  fetchContract ({ commit }, id) {
    commit(CONTRACTS_FETCH_REQUEST)
    return window.axios.get(`/contracts/${id}`).then(response => {
      commit(CONTRACTS_FETCH_SUCCESS, response)
      return response
    }).catch(error => {
      commit(CONTRACTS_FETCH_FAILURE, error)
      throw error
    })
  },
  saveContract ({ commit }, payload) {
    const path = payload.id ? `/contracts/${payload.id}` : '/contracts'
    const method = payload.id ? 'put' : 'post'

    commit(CONTRACTS_SAVE_REQUEST)
    return window.axios[method](path, payload).then(response => {
      commit(CONTRACTS_SAVE_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(CONTRACTS_SAVE_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(CONTRACTS_SAVE_FAILURE, { error })
      }
      throw error
    })
  },
  uploadDocument ({ commit }, { id, data }) {
    commit(CONTRACTS_UPLOAD_REQUEST)
    return window.axios.post(`/contracts/${id}/upload`, data).then(response => {
      commit(CONTRACTS_UPLOAD_SUCCESS, response)
      return response
    }).catch(error => {
      if (error.response.status === 422) {
        commit(CONTRACTS_UPLOAD_FAILURE, { validationErrors: error.response.data.errors })
      } else {
        commit(CONTRACTS_UPLOAD_FAILURE, { error })
      }
      throw error
    })
  }
}
