<template>
  <b-container>
    <ResourceList
      can-delete create-to="energy.contracts.create" resource="contract" soft-deletes title="Contracts"
      :data="data" :is-deleting="isDeleting" :is-loading="isLoading" :pagination="pagination"
      :search-query="$route.query.query" @delete-many="onDeleteMany" @paginate="onPaginate"
      @search="onSearch">

      <template #buttons>
        <!-- <b-dd-item-button :disabled="currentUserCan(permissions.CAN_SEND_PASSWORD_RESET_EMAIL)">Send Password Reset Email</b-dd-item-button> -->
      </template>

      <template #listItem="{ item }">
        <div class="d-flex flex-column flex-fill">
          <div class="d-flex flex-row flex-fill">
            <p class="h5 flex-fill mb-1 text-primary"><router-link class="text-dark" :to="{name: 'energy.contracts.update', params: { id: item.id }}">{{ item.site.customer.company_name }} <b-icon icon="chevron-right" /> {{ item.site.address.postcode }} ({{ item.meters_count === 1 ? item.meters_count + ' meter' : item.meters_count + ' meters' }})</router-link></p>
            <FriendlyDate class="align-self-end mb-1 text-muted" :date="item.created_at" />
          </div>
          <div class="d-flex flex-row flex-fill mb-0">
            <p class="mb-0 flex-fill text-muted">
              <b-icon :class="{'text-warning': item.meters.filter(m => m.meter && m.meter.type === 'electric').length}" icon="lightning-fill" />
              <b-icon :class="{'text-danger': item.meters.filter(m => m.meter && m.meter.type === 'gas').length}" icon="sun" />
              <b-icon :class="{'text-info': item.meters.filter(m => m.meter && m.meter.type === 'water').length}" icon="droplet-half" />
              &bull; Contract live between {{ moment(item.start_date).format('DD/MM/YYYY') }} and {{ moment(item.end_date).format('DD/MM/YYYY') }}.
            </p>
          </div>
        </div>
      </template>

    </ResourceList>
  </b-container>
</template>

<script>
import FriendlyDate from '../../components/FriendlyDate'
import ResourceList from '../../components/ResourceList'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, momentMixin],
  created () {
    this.fetchManyContracts(this.$route.query)
  },
  components: {
    FriendlyDate,
    ResourceList
  },
  computed: {
    ...mapGetters('contracts', ['data', 'isDeleting', 'isLoading', 'pagination'])
  },
  methods: {
    ...mapActions('contracts', ['deleteManyContracts', 'fetchManyContracts']),
    async onDeleteMany ({ ids, modalId }) {
      await this.deleteManyContracts({ ids })
      this.$bvModal.hide(modalId)
      await this.fetchManyContracts(this.$route.query)
    },
    async onPaginate (page) {
      if (this.$route.query.page !== page) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, page } })
        await this.fetchManyContracts({ ...this.$route.query, page })
      }
    },
    async onSearch (query) {
      if (this.$route.query.query !== query) {
        this.$router.push({ name: this.$route.name, query: { ...this.$route.query, query } })
        await this.fetchManyContracts({ ...this.$route.query, query })
      }
    }
  }
}
</script>

<style>

</style>
