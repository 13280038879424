<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Contract</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="6">
              <b-card class="mb-4">
                <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
                  <div class="flex-fill" v-if="!selectedCustomer || selectedCustomer.id !== this.customer_id">
                    <p class="h3 font-weight-bold text-muted">Assign a customer</p>
                    <p class="mb-0 text-muted">A meter must belong to a customer.</p>
                  </div>
                  <div class="flex-fill mr-2 minw-0" v-if="(selectedCustomer && selectedCustomer.id) === this.customer_id">
                    <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ selectedCustomer.company_name }}</p>
                    <p class="mb-0 text-muted">{{ selectedCustomer.address && selectedCustomer.address.address_snippet }}</p>
                  </div>
                  <div class="align-self-lg-center d-flex flex-column mt-2 mt-lg-0">
                    <b-button block class="text-nowrap" variant="secondary" v-b-modal.assign-customer>Assign Customer</b-button>
                  </div>
                </b-card-text>
              </b-card>
              <b-modal id="assign-customer" size="lg" cancel-title="Close" ok-title="Assign"
                title="Assign Customer" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selectedCustomer"
                @ok="customer_id = selectedCustomer.id" body-class="p-0">
                <div class="p-3 pb-0">
                  <b-form-group class="mb-0" label="Customer Search" label-for="customer-search" label-sr-only>
                    <b-form-input debounce="1000" @update="fetchManyCustomers({ query: customerSearchQuery })" id="customer-search" v-model="customerSearchQuery" v-b-popover.hover.top="'Search for customers that have already been added to the system.'" />
                  </b-form-group>
                </div>
                <div class="text-center mt-2 mb-4" v-if="isSearching">
                  <b-spinner variant="secondary" />
                </div>
                <b-list-group class="border-top" flush v-if="!isSearching && customerSearchResults && customerSearchResults.length">
                  <b-list-group-item button @click.prevent="selectedCustomer = customer" :class="{'bg-selected': customer.id === (selectedCustomer && selectedCustomer.id)}" :key="customer.id" v-for="customer in customerSearchResults">
                    <div class="d-flex flex-column flex-fill">
                      <p class="h5 flex-fill mb-0 text-primary">{{ customer.company_name }}</p>
                      <p class="text-muted mb-0"><small>{{ customer.company_number }} - {{ customer.address && customer.address.address_snippet }}</small></p>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-modal>
            </b-col>
            <b-col cols="12" md="6">
              <b-card class="mb-4">
                <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
                  <div class="flex-fill" v-if="!selectedSite || selectedSite.id !== this.site_id">
                    <p class="h3 font-weight-bold text-muted">Assign a site</p>
                    <p class="mb-0 text-muted">A meter must belong to a site.</p>
                  </div>
                  <div class="flex-fill mr-2 minw-0" v-if="(selectedSite && selectedSite.id) === this.site_id">
                    <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ selectedSite.address && selectedSite.address.postcode }}</p>
                    <p class="mb-0 text-muted">{{ selectedSite.address && selectedSite.address.address_snippet }}</p>
                  </div>
                  <div class="align-self-lg-center d-flex flex-column mt-2 mt-lg-0">
                    <b-button block :disabled="!this.customer_id" class="text-nowrap" variant="secondary" v-b-modal.assign-site>Assign Site</b-button>
                  </div>
                </b-card-text>
              </b-card>
              <b-modal id="assign-site" size="lg" cancel-title="Close" ok-title="Assign"
                title="Assign Site" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selectedSite"
                @ok="site_id = selectedSite.id" body-class="p-0">
                <div class="text-center my-4" v-if="isSearchingSites">
                  <b-spinner variant="secondary" />
                </div>
                <b-list-group flush v-if="!isSearching && siteSearchResults && siteSearchResults.length">
                  <b-list-group-item button @click.prevent="selectedSite = site" :class="{'bg-selected': site.id === (selectedSite && selectedSite.id)}" :key="site.id" v-for="site in siteSearchResults">
                    <div class="d-flex flex-column flex-fill">
                      <p class="h5 flex-fill mb-0 text-primary">{{ site.customer && site.customer.company_name }} ({{ site.address && site.address.postcode }})</p>
                      <p class="text-muted mb-0"><small>{{ site.customer && site.customer.company_number }} - {{ site.address && site.address.address_snippet }}</small></p>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Portal" :invalid-feedback="validationInvalidFeedback(errors, 'portal')" :state="validationState(errors, 'portal')">
                <b-form-input :disabled="isSaving" type="text" v-model="portal" />
                <b-form-checkbox class="mt-2" name="online_direct" v-model="online_direct">This is an Online Direct contract.</b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Payment Reference" :invalid-feedback="validationInvalidFeedback(errors, 'payment_ref')" :state="validationState(errors, 'payment_ref')">
                <b-form-input :disabled="isSaving" type="text" v-model="payment_ref" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-card>
                <b-form-checkbox v-model="live">Contract is live</b-form-checkbox>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" md="3">
              <b-form-group label="Date of Sale" :invalid-feedback="validationInvalidFeedback(errors, 'deal_closed_at')" :state="validationState(errors, 'deal_closed_at')">
                <b-form-datepicker :disabled="isSaving" :date-format-options="{'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}" v-model="deal_closed_at" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="Start Date" :invalid-feedback="validationInvalidFeedback(errors, 'start_date')" :state="validationState(errors, 'start_date')">
                <b-form-datepicker :disabled="isSaving" :date-format-options="{'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}" v-model="start_date" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="End Date" :invalid-feedback="validationInvalidFeedback(errors, 'end_date')" :state="validationState(errors, 'end_date')">
                <b-form-datepicker :disabled="isSaving" :date-format-options="{'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}" v-model="end_date" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="Total Contract Value">
                <b-input-group prepend="£">
                  <b-form-input disabled v-model="metersRevenue" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-card title="Contract Termination">
                <b-row class="mb-4">
                  <b-col cols="12">
                    <b-checkbox v-model="term_requested">Termination Requested?</b-checkbox>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col cols="12" md="6">
                    <b-form-group label="Date of Application" :invalid-feedback="validationInvalidFeedback(errors, 'term_applied_at')" :state="validationState(errors, 'term_applied_at')">
                      <b-form-datepicker :disabled="isSaving || !term_requested" :date-format-options="{'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}" v-model="term_applied_at" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Date of Acceptance" :invalid-feedback="validationInvalidFeedback(errors, 'term_accepted_at')" :state="validationState(errors, 'term_accepted_at')">
                      <b-form-datepicker :disabled="isSaving || !term_requested" :date-format-options="{'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}" v-model="term_accepted_at" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="mb-2" cols="12" md="8">
              <h2>Meters</h2>
            </b-col>
            <b-col cols="12" md="4">
              <b-button block :disabled="!site_id" variant="primary" v-b-modal.edit-meter>Add Meter</b-button>
            </b-col>
            <b-col class="mt-2" cols="12">
              <b-table responsive bordered striped head-variant="dark" :items="meters"
                :fields="[{ key: 'ref', label: 'Reference' }, { key: 'aq', label: 'AQ' }, 'supplier', { key: 'uplift', label: 'Uplift' }, { key: 'scUplift', label: 'SC Uplift' }, 'actions']">
                <template v-slot:cell(ref)="row">
                  <span class="text-nowrap">
                    <b-icon variant="info" icon="droplet-half" v-if="get(row, 'item.meter.type') === 'water'" />
                    <b-icon variant="warning" icon="lightning-fill" v-if="get(row, 'item.meter.type') === 'electric'"/>
                    <b-icon variant="danger" icon="sun" v-if="get(row, 'item.meter.type') === 'gas'"/>
                    {{ get(row, 'item.ref') }}
                  </span>
                </template>

                <template v-slot:cell(actions)="row">
                  <b-button variant="primary" size="sm" @click="row.toggleDetails" class="mr-2 text-nowrap">{{ row.detailsShowing ? 'Hide' : 'Show'}} Details</b-button>
                </template>

                <template v-slot:cell(aq)="row">
                  <span class="text-nowrap">{{ new Intl.NumberFormat('en-GB').format(get(row, 'item.aq')) }} {{ get(row, 'item.meter.type') === 'water' ? 'm³' : 'kWh' }}</span>
                </template>

                <template v-slot:cell(supplier)="{ item }">
                  <img :alt="item.supplier.name" :src="`/api${item.supplier.logo}`" height=36 v-if="item.supplier && item.supplier.logo" />
                  {{ item.supplier && !item.supplier.logo ? item.supplier.name : '' }}
                </template>

                <template v-slot:cell(uplift)="row">
                  <span class="text-nowrap" v-if="get(row, 'item.meter.type') !== 'water'">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(get(row, 'item.uplift') / 100) }}/{{ get(row, 'item.meter.type') === 'water' ? 'm³' : 'kWh' }}</span>
                  <span class="text-nowrap" v-if="get(row, 'item.meter.type') === 'water'">N/A</span>
                </template>

                <template v-slot:cell(scUplift)="row">
                  <span class="text-nowrap" v-if="get(row, 'item.meter.type') !== 'water'">{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(get(row, 'item.scUplift') / 100) }}/{{ get(row, 'item.meter.type') === 'water' ? 'm³' : 'kWh' }}</span>
                  <span class="text-nowrap" v-if="get(row, 'item.meter.type') === 'water'">N/A</span>
                </template>

                <template v-slot:row-details="row">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><strong>Supply Type:</strong></b-col>
                      <b-col><b-badge variant="warning" v-if="get(row, 'item.meter.type') === 'electric'">Electricity</b-badge><b-badge variant="danger" v-if="get(row, 'item.meter.type') === 'gas'">Gas</b-badge><b-badge variant="info" v-if="get(row, 'item.meter.type') === 'water'">Water</b-badge></b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><strong v-if="get(row, 'item.meter.type') === 'electric'">MPAN:</strong><strong v-if="get(row, 'item.meter.type') === 'gas'">MPRN:</strong><strong v-if="get(row, 'item.meter.type') === 'water'">SPID:</strong></b-col>
                      <b-col>{{ get(row, 'item.ref') }}</b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><strong>Annual Quantity:</strong></b-col>
                      <b-col>{{ new Intl.NumberFormat('en-GB').format(get(row, 'item.aq')) }} {{ get(row, 'item.meter.type') === 'water' ? 'm³' : 'kWh' }}</b-col>
                    </b-row>
                    <b-row class="mb-2" v-if="get(row, 'item.meter.type') !== 'water'">
                      <b-col sm="3" class="text-sm-right"><strong>Uplift:</strong></b-col>
                      <b-col>{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(get(row, 'item.uplift') / 100) }}/{{ get(row, 'item.meter.type') === 'water' ? 'm³' : 'kWh' }}</b-col>
                    </b-row>
                    <b-row class="mb-2" v-if="get(row, 'item.meter.type') !== 'water'">
                      <b-col sm="3" class="text-sm-right"><strong>SC Uplift:</strong></b-col>
                      <b-col>{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 2, maximumFractionDigits: 4 }).format(get(row, 'item.scUplift') / 100) }}/day</b-col>
                    </b-row>
                    <b-row class="mb-2" v-if="get(row, 'item.meter.type') !== 'water'">
                      <b-col sm="3" class="text-sm-right"><strong>Meter Revenue:</strong></b-col>
                      <b-col>{{ new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(calcMeterRevenue(row.index)) }}</b-col>
                    </b-row>

                    <b-button variant="primary" size="sm" @click="row.toggleDetails" class="mr-2">Hide Details</b-button>
                    <b-button variant="secondary" size="sm" @click="showEditMeter(row.index)" class="mr-2">Edit Meter</b-button>
                    <b-button variant="danger" size="sm" @click="removeMeter(row.index)" class="mr-2">Remove</b-button>
                  </b-card>
                </template>
              </b-table>
              <b-modal id="edit-meter" size="lg" cancel-title="Close" ok-title="Add"
                title="Add Meter" ok-variant="secondary" cancel-variant="dark"
                @ok="addMeter" body-class="p-0" :ok-disabled="!editMeter && (!editMeter.meter || !editMeter.terms)">
                <div class="text-center my-4" v-if="isSearchingMeters">
                  <b-spinner variant="secondary" />
                </div>
                <b-list-group class="border-bottom" flush v-if="!isSearchingMeters && meterSearchResults && meterSearchResults.length" style="max-height: 284px;
    overflow: scroll;">
                  <b-list-group-item button @click.prevent="editMeter.meter = meter" :class="{'bg-selected': meter.id === (editMeter.meter && editMeter.meter.id)}" :key="meter.id" v-for="meter in meterSearchResults">
                    <div class="d-flex flex-column flex-fill">
                      <p class="h5 flex-fill mb-0 text-primary">{{ meter.site && meter.site.customer && meter.site.customer.company_name }} ({{ meter.site && meter.site.address && meter.site.address.postcode }})</p>
                      <p class="d-flex flex-row text-muted mt-1 mb-0 w-50">
                        <span class="badge mr-1 w-25" :class="{'badge-danger': meter.type === 'gas', 'badge-warning': meter.type === 'electric', 'badge-info': meter.type === 'water'}">{{ meter.type.charAt(0).toUpperCase() + meter.type.slice(1) }}</span>
                        <span class="badge badge-primary flex-fill" v-if="meter.type === 'electric'">{{ [meter.top_line && meter.top_line.slice(0, 2), meter.top_line && meter.top_line.slice(2, 5), meter.top_line && meter.top_line.slice(5, 8)].join(' ') }}{{ meter.top_line ? ' / ' : '' }}{{ [meter.mpan_core.slice(0, 2), meter.mpan_core.slice(2, 6), meter.mpan_core.slice(6, 10), meter.mpan_core.slice(10, 13)].join(' ') }}</span>
                        <span class="badge badge-primary flex-fill" v-if="meter.type === 'gas'">{{ meter.mprn }}</span>
                        <span class="badge badge-primary flex-fill" v-if="meter.type === 'water'">{{ meter.spid }}</span>
                      </p>
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <div class="p-3">
                  <b-row>
                    <b-col cols="12">
                      <b-card class="mb-4">
                        <div class="d-flex flex-row">
                          <div class="align-self-center flex-fill" v-if="!editMeter.supplier || !editMeter.terms">
                            <p class="font-weight-bold h3 mb-1 text-muted">Supplier &amp; Payment Terms</p>
                            <p class="mb-0 text-muted">Select a supplier and their payment terms for this contract.</p>
                          </div>
                          <div class="align-self-center flex-fill" v-if="editMeter.supplier && editMeter.terms">
                            <p class="font-weight-bold h3 mb-1 text-primary">{{ editMeter.supplier.name }} - {{ editMeter.terms.name }}</p>
                            <p class="mb-0 text-muted">{{ describePaymentTerms(editMeter.terms) }}</p>
                          </div>
                          <div class="align-self-center">
                            <b-button variant="secondary" v-b-modal.get-supplier>Assign Supplier</b-button>
                          </div>
                        </div>
                      </b-card>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Annual Quantity">
                        <b-input-group :append="editMeter.meter && editMeter.meter.type === 'water' ? 'm³' : 'kWh'">
                          <b-form-input type="number" step="1000" v-model.number="editMeter.aq" />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="Contract Value">
                        <p class="mb-0 text-muted" v-if="!start_date || !end_date"><small>Please set a start date and end date to see contract value.</small></p>
                        <b-input-group prepend="£" v-if="start_date && end_date">
                          <b-form-input disabled v-model="editMeterTotalRevenue" />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" v-if="editMeter.meter && editMeter.meter.type !== 'water'">
                      <b-form-group label="Uplift">
                        <b-input-group append="p">
                          <b-form-input type="number" min="0" max="3" step="0.1" v-model.number="editMeter.uplift" />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" v-if="editMeter.meter && editMeter.meter.type !== 'water'">
                      <b-form-group label="SC Uplift">
                        <b-input-group append="p">
                          <b-form-input type="number" min="0" step="1" v-model.number="editMeter.scUplift" />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-modal>
              <b-modal id="get-supplier" size="xl" cancel-title="Close" ok-title="Associate"
                title="Supplier &amp; Payment Terms" ok-variant="secondary" cancel-variant="dark"
                @ok="editMeter.supplier = selectedSupplier; editMeter.terms = contractPaymentTerms" body-class="p-0"
                :ok-disabled="!contractPaymentTerms.how || !contractPaymentTerms.when || !selectedSupplier">
                <div class="d-flex flex-row w-100" style="height: 400px;">
                  <div class="border-bottom w-50">
                    <div class="text-center my-4" v-if="isLoadingSuppliers">
                      <b-spinner variant="secondary" />
                    </div>
                    <b-list-group class="h-100" flush v-if="!isLoadingSuppliers && suppliers && suppliers.length" style="overflow: scroll;">
                      <b-list-group-item button @click.prevent="selectedSupplier = supplier" :class="{'bg-selected': supplier.id === (selectedSupplier && selectedSupplier.id)}" :key="supplier.id" v-for="supplier in suppliers">
                        <div class="d-flex flex-row">
                          <b-avatar class="mr-3" :src="supplierLogoUrl(supplier)" :text="supplier && supplier.name.split(' ').map(n => n && n.charAt(0)).slice(0, 2).join('').toUpperCase()"></b-avatar>
                          <div class="d-flex flex-column flex-fill">
                            <p class="h5 mb-0 text-primary">{{ supplier.name }}</p>
                            <p class="mb-0 text-muted">{{ supplier.terms && supplier.terms.length }} payment terms defined</p>
                          </div>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                  <div class="border-bottom border-left w-50">
                    <b-list-group class="h-100" flush v-if="selectedSupplier && selectedSupplier.terms && selectedSupplier.terms.length" style="overflow: scroll;">
                      <b-list-group-item button @click.prevent="selectedPaymentTerms = terms" :class="{'bg-selected': terms === selectedPaymentTerms}" :key="terms.name" v-for="terms in selectedSupplier.terms">
                        <p class="h5 mb-0 text-primary">{{ terms.name }}</p>
                        <p class="text-muted">{{ describePaymentTerms(terms) }}</p>
                      </b-list-group-item>
                    </b-list-group>
                  </div>
                </div>
                <div class="p-3">
                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Name">
                        <b-form-input type="text" v-model="contractPaymentTerms.name" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="6">
                      <b-form-group label="How will this supplier pay us?">
                        <b-form-radio-group buttons class="w-100" button-variant="primary" v-model="contractPaymentTerms.how" :options="[{ text: 'In Arrears', value: 'arrears' }, { text: 'Percentages', value: 'percentages' }, { text: 'Upfront', value: 'upfront' }]"></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" v-if="contractPaymentTerms.how !== 'upfront'">
                      <b-form-group label="How often will the supplier pay us?">
                        <b-form-radio-group buttons class="w-100" button-variant="primary" v-model="contractPaymentTerms.when" :options="howPaidOptions"></b-form-radio-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="6" v-if="contractPaymentTerms.how === 'upfront'">
                      <b-form-group label="How large is the upfront payment?">
                        <b-input-group prepend="£">
                          <b-form-input type="number" step="0.01" v-model="contractPaymentTerms.upfront"></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12">
                      <b-form-group label="First Payment after Start Date">
                        <b-input-group append="days">
                          <b-form-input v-model.number="contractPaymentTerms.first_payment" />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" v-if="contractPaymentTerms.how === 'percentages'">
                      <b-form-group label="Percentages Paid">
                        <b-input-group>
                          <b-form-input title="Sign" v-b-popover.hover.top="'Represents the percentage of the total revenue which is paid when the contract is signed.'" v-model="contractPaymentTerms.perc.sign" />
                          <b-form-input title="Live" v-b-popover.hover.top="'Represents the percentage of the total revenue which is paid when the supply of energy goes live, or the year starts.'" v-model="contractPaymentTerms.perc.live" />
                          <b-form-input title="End" v-b-popover.hover.top="'Represents the percentage of the total revenue which is paid when the contract ends, or the year ends.'" v-model="contractPaymentTerms.perc.end" />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-card class="mb-4">
                <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
                  <div class="align-self-lg-center flex-fill" v-if="!selectedLeadGenerator || selectedLeadGenerator.id !== this.lead_generated_by_id">
                    <p class="h3 font-weight-bold text-muted">Lead Generated By</p>
                    <p class="mb-0 text-muted">Who generated this lead?</p>
                  </div>
                  <div class="align-self-lg-center flex-fill mr-2 minw-0" v-if="(selectedLeadGenerator && selectedLeadGenerator.id) === this.lead_generated_by_id">
                    <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ [selectedLeadGenerator.forename, selectedLeadGenerator.surname].join(' ') }}</p>
                    <p class="mb-0 text-muted">{{ selectedLeadGenerator.email }}</p>
                  </div>
                  <div class="d-flex flex-column mt-2 mt-lg-0">
                    <b-button block class="my-1" variant="secondary" v-b-modal.assign-leadgen>Assign Rep</b-button>
                    <b-button block class="my-1" variant="primary" @click.prevent="selectedLeadGenerator = currentUser; lead_generated_by_id = currentUser.id">Assign Myself</b-button>
                  </div>
                </b-card-text>
              </b-card>
              <b-modal id="assign-leadgen" size="lg" cancel-title="Close" ok-title="Assign"
                title="Assign Lead Generator" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selectedLeadGenerator"
                @ok="lead_generated_by_id = selectedLeadGenerator.id" body-class="p-0">
                <div class="p-3 pb-0">
                  <b-form-group class="mb-0" label="User Search" label-for="user-search" label-sr-only>
                    <b-form-input debounce="1000" @update="fetchManyUsers({ query: userSearchQuery })" id="user-search" v-model="userSearchQuery" v-b-popover.hover.top="'Search for sales representatives.'" />
                  </b-form-group>
                </div>
                <div class="text-center my-4" v-if="isSearchingUsers">
                  <b-spinner variant="secondary" />
                </div>
                <b-list-group class="border-top" flush v-if="!isSearchingUsers && userSearchResults && userSearchResults.length">
                  <b-list-group-item button @click.prevent="selectedLeadGenerator = user" :class="{'bg-selected': user.id === (selectedLeadGenerator && selectedLeadGenerator.id)}" :key="user.id" v-for="user in userSearchResults">
                    <div class="d-flex flex-column flex-fill">
                      <p class="h5 flex-fill mb-0 text-primary">{{ [user.forename, user.surname].join(' ') }}</p>
                      <p class="text-muted mb-0"><small>{{ user.email }}</small></p>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-modal>
            </b-col>
            <b-col cols="12" md="6">
              <b-card class="mb-4">
                <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
                  <div class="align-self-lg-center flex-fill" v-if="!selectedClosedBy || selectedClosedBy.id !== this.deal_closed_by_id">
                    <p class="h3 font-weight-bold text-muted">Deal Closed By</p>
                    <p class="mb-0 text-muted">Who closed this deal?</p>
                  </div>
                  <div class="align-self-lg-center flex-fill mr-2 minw-0" v-if="(selectedClosedBy && selectedClosedBy.id) === this.deal_closed_by_id">
                    <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ [selectedClosedBy.forename, selectedClosedBy.surname].join(' ') }}</p>
                    <p class="mb-0 text-muted">{{ selectedClosedBy.email }}</p>
                  </div>
                  <div class="d-flex flex-column mt-2 mt-lg-0">
                    <b-button block class="my-1" variant="secondary" v-b-modal.assign-closedby>Assign Rep</b-button>
                    <b-button block class="my-1" variant="primary" @click.prevent="selectedClosedBy = currentUser; deal_closed_by_id = currentUser.id">Assign Myself</b-button>
                  </div>
                </b-card-text>
              </b-card>
              <b-modal id="assign-closedby" size="lg" cancel-title="Close" ok-title="Assign"
                title="Assign Closed By" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selectedClosedBy"
                @ok="deal_closed_by_id = selectedClosedBy.id" body-class="p-0">
                <div class="p-3 pb-0">
                  <b-form-group class="mb-0" label="User Search" label-for="user-search" label-sr-only>
                    <b-form-input debounce="1000" @update="fetchManyUsers({ query: userSearchQuery })" id="user-search" v-model="userSearchQuery" v-b-popover.hover.top="'Search for sales representatives.'" />
                  </b-form-group>
                </div>
                <div class="text-center my-4" v-if="isSearchingUsers">
                  <b-spinner variant="secondary" />
                </div>
                <b-list-group class="border-top" flush v-if="!isSearchingUsers && userSearchResults && userSearchResults.length">
                  <b-list-group-item button @click.prevent="selectedClosedBy = user" :class="{'bg-selected': user.id === (selectedClosedBy && selectedClosedBy.id)}" :key="user.id" v-for="user in userSearchResults">
                    <div class="d-flex flex-column flex-fill">
                      <p class="h5 flex-fill mb-0 text-primary">{{ [user.forename, user.surname].join(' ') }}</p>
                      <p class="text-muted mb-0"><small>{{ user.email }}</small></p>
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2>Documents</h2>
                </b-col>
                <b-col cols="12" md="4">
                  <b-button block :disabled="!$route.params.id" variant="primary" v-b-modal.upload-document>Upload Document</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['filename', { key: 'url', label: 'URL' }, { key: 'created_at', label: 'Uploaded At' }]" :items="documents">
                    <template v-slot:cell(url)="row">
                      <a :href="downloadURL(row.item.url)">Download</a>
                    </template>
                    <template v-slot:cell(created_at)="row">
                      <friendly-date class="mb-0" :date="row.item.created_at"/>
                      <p class="mb-0"><em>{{ row.item.created_by ? `by ${[row.item.created_by.forename, row.item.created_by.surname].join(' ')}` : '' }}</em></p>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
              <b-modal id="upload-document" cancel-variant="dark" ok-variant="secondary" title="Upload Document" size="md"
               @ok.prevent="uploadContractDocument" @cancel="canceluploadContractDocument">
                <template v-slot:modal-ok>
                  <span v-if="!isUploading">Upload</span><b-spinner class="mx-4" small variant="light" v-if="isUploading" />
                </template>
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Upload Document">
                      <b-form-file v-model="uploadDocumentFile" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-modal>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col class="d-flex flex-row" cols="12" md="6" offset-md="6" lg="4" offset-lg="8">
              <b-button block class="align-self-end mb-3" :disabled="isSaving" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import moment from 'moment'
import map from 'lodash/map'
import get from 'lodash/get'
import normalizeURL from 'normalize-url'
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import validation from '../../mixins/validation'
import FriendlyDate from '../../components/FriendlyDate'
import { mapActions, mapGetters } from 'vuex'

const initialData = () => ({
  meters: [],
  documents: [],
  editMeter: {
    id: 0,
    aq: 0,
    ref: '',
    type: '',
    meter: null,
    terms: null,
    uplift: 1.5,
    scUplift: 0,
    supplier: null
  },
  contractPaymentTerms: {
    how: '',
    when: '',
    upfront: 200,
    first_payment: 30,
    perc: {
      live: 80,
      sign: 0,
      end: 20
    }
  },

  live: false,
  portal: null,
  end_date: null,
  start_date: null,
  payment_ref: null,
  online_direct: false,
  date_of_sale: moment().toDate(),

  site_id: null,
  customer_id: null,
  deal_closed_at: null,
  deal_closed_by_id: null,
  lead_generated_by_id: null,

  term_requested: false,
  term_applied_at: null,
  term_accepted_at: null,

  selectedSite: {},
  selectedCustomer: {},
  selectedClosedBy: {},
  selectedSupplier: {},
  selectedPaymentTerms: {},
  selectedLeadGenerator: {},

  userSearchQuery: null,
  uploadDocumentFile: null,
  customerSearchQuery: null
})

export default {
  components: { FriendlyDate },
  mixins: [currentUser, momentMixin, validation],
  created () {
    this.fetchManySuppliers({ paginate: false })
    if (this.$route.params.id) {
      this.fetchContract(this.$route.params.id)
    }
  },
  data () {
    return initialData()
  },
  beforeRouteLeave (_0, _1, next) {
    this.$store.dispatch('contracts/reset')
    Object.assign(this.$data, initialData())
    return next()
  },
  computed: {
    ...mapGetters('energy-customers', { customerSearchResults: 'data', isSearching: 'isLoading' }),
    ...mapGetters('energy-sites', { siteSearchResults: 'data', isSearchingSites: 'isLoading' }),
    ...mapGetters('suppliers', { suppliers: 'data', isLoadingSuppliers: 'isLoading' }),
    ...mapGetters('meters', { meterSearchResults: 'data', isSearchingMeters: 'isLoading' }),
    ...mapGetters('users', { userSearchResults: 'data', isSearchingUsers: 'isLoading' }),
    ...mapGetters('contracts', ['errors', 'isLoading', 'isSaving', 'isUploading', 'single']),
    editMeterTotalRevenue () {
      let v = (
        Math.abs(this.moment(this.end_date).diff(this.start_date, 'months', false)) *
        (this.editMeter.aq / 12) * (this.editMeter.uplift / 100)
      ) + (Math.abs(this.moment(this.end_date).diff(this.start_date, 'days', false)) * (this.editMeter.scUplift / 100))
      if (this.online_direct) {
        v = v / 5 * 4
      }

      return new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(v)
    },
    paidInArrears () {
      return this.contractPaymentTerms.how === 'arrears' ? this.contractPaymentTerms.when : null
    },
    paidPercentage () {
      return this.contractPaymentTerms.how === 'percentages' ? this.contractPaymentTerms.when : null
    },
    howPaidOptions () {
      return this.contractPaymentTerms.how === 'percentages'
        ? [{ text: 'Contract Period', value: 'contract' }, { text: 'Annually', value: 'annually' }]
        : [{ text: 'Monthly', value: 'monthly' }, { text: 'Quarterly', value: 'quarterly' }]
    },
    metersRevenue () {
      return new Intl.NumberFormat('en-GB', { minimumFractionDigits: 2 }).format(this.meters.reduce((a, _, i) => a + this.calcMeterRevenue(i), 0))
    }
  },
  methods: {
    get,
    ...mapActions('energy-customers', ['fetchManyCustomers']),
    ...mapActions('energy-sites', ['fetchManySites']),
    ...mapActions('suppliers', ['fetchManySuppliers']),
    ...mapActions('meters', ['fetchManyMeters']),
    ...mapActions('users', ['fetchManyUsers']),
    ...mapActions('contracts', ['fetchContract', 'saveContract', 'uploadDocument']),
    supplierLogoUrl (supplier) {
      return new URL(process.env.VUE_APP_API_URL + supplier.logo).toString()
    },
    downloadURL (path) {
      return path[0] === '/' ? normalizeURL(process.env.VUE_APP_API_URL + path) : path
    },
    uploadContractDocument () {
      const formData = new FormData()
      formData.append('file', this.uploadDocumentFile, this.uploadDocumentFile.name)
      this.uploadDocument({ id: this.$route.params.id, data: formData }).then(res => {
        this.documents.unshift(res.data)
        this.uploadDocumentFile = null
        this.$bvModal.hide('upload-document')
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: 'Document uploaded successfully',
          text: 'The document has been assigned to this customer.'
        })
      }).catch(err => {
        if (err.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Error uploading document',
            text: `Error for debugging: ${err.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    },
    canceluploadContractDocument () {
      this.uploadDocumentFile = null
    },
    describePaymentTerms (paymentTerms) {
      switch (paymentTerms.how) {
        case 'percentages':
          return `${paymentTerms.perc.sign > 0 ? `${paymentTerms.perc.sign}% paid upon signing the contract.` : ''} ${paymentTerms.perc.live}% paid at the start of the ${paymentTerms.when === 'contract' ? 'contract' : 'year'}, then ${paymentTerms.perc.end}% paid at the end of the ${paymentTerms.when === 'contract' ? 'contract' : 'year'}.`.trim()
        case 'arrears':
          return `Payment in arrears on a ${paymentTerms.when} basis.`
        case 'upfront':
          return `Upfront payment of ${new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(paymentTerms.upfront)}`
      }
    },
    addMeter () {
      if (typeof this.editMeter.index === 'undefined') {
        this.meters.push(this.editMeter)
      } else {
        this.meters.splice(this.editMeter.index, 1, this.editMeter)
      }

      this.editMeter = {
        id: 0,
        aq: 0,
        ref: '',
        type: '',
        meter: null,
        terms: null,
        uplift: 1.5,
        scUplift: 0,
        supplier: null
      }
      this.$bvModal.hide('edit-meter')
    },
    cancelMeter () {
      this.editMeter = {
        id: 0,
        aq: 0,
        ref: '',
        type: '',
        meter: null,
        terms: null,
        uplift: 1.5,
        scUplift: 0,
        supplier: null
      }
    },
    removeMeter (index) {
      this.meters.splice(index, 1)
    },
    showEditMeter (index) {
      this.editMeter = { ...this.meters[index], index }
      this.meters.splice(index, 1, this.editMeter)
      this.$bvModal.show('edit-meter')
    },
    calcMeterRevenue (ind) {
      const { aq, scUplift, uplift } = this.meters[ind]
      let v = (
        Math.abs(this.moment(this.end_date).diff(this.start_date, 'months', false)) *
        (aq / 12) * (uplift / 100)
      ) + (Math.abs(this.moment(this.end_date).diff(this.start_date, 'days', false)) * (scUplift / 100))
      if (this.online_direct) {
        v = v / 5 * 4
      }

      return v
    },
    submit () {
      this.saveContract({
        id: this.$route.params.id,
        portal: this.portal,
        site_id: this.site_id,
        end_date: this.end_date,
        start_date: this.start_date,
        payment_ref: this.payment_ref,
        online_direct: this.online_direct,
        deal_closed_at: this.deal_closed_at,
        term_requested: this.term_requested,
        term_applied_at: this.term_applied_at,
        term_accepted_at: this.term_accepted_at,
        deal_closed_by_id: this.deal_closed_by_id,
        lead_generator_id: this.lead_generated_by_id,
        live: this.single.live === this.live ? null : this.live,
        meters: this.meters.map(m => ({
          supplier_id: m.supplier.id,
          sc_uplift: m.scUplift,
          meter_id: m.meter.id,
          uplift: m.uplift,
          terms: m.terms,
          aq: m.aq,
          id: m.id
        }))
      }).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: `Contract ${this.$route.params.id ? 'Updated' : 'Created'} Successfully`,
          text: 'This contract has successfully been saved.'
        })

        if (!this.$route.params.id) {
          return this.$router.push({ name: 'energy.contracts.update', params: { id: response.data.id } })
        }

        return this.fetchContract(this.$route.params.id)
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updated' : 'Created'} Customer`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    customer_id (value) {
      this.fetchManySites({ customerId: value })
    },
    'editMeter.meter' (value) {
      const topLine = get(value, 'top_line')
      const mpanCore = get(value, 'mpan_core')

      this.editMeter.aq = get(value, 'consumption')
      this.editMeter.ref = get(value, 'type') === 'electric'
        ? `${[topLine?.slice(0, 2), topLine?.slice(2, 5), topLine?.slice(5, 8)].join(' ')} / ${[mpanCore?.slice(0, 2), mpanCore?.slice(2, 6), mpanCore?.slice(6, 10), mpanCore?.slice(10, 13)].join(' ')}`
        : get(value, 'type') === 'gas'
          ? get(value, 'mprn')
          : get(value, 'spid')
      this.editMeter.type = get(value, 'type')
    },
    site_id (value) {
      this.fetchManyMeters({ siteId: value })
    },
    selectedPaymentTerms (value) {
      this.contractPaymentTerms = JSON.parse(JSON.stringify(value))
    },
    single (value) {
      this.live = get(value, 'live')
      this.portal = get(value, 'portal')
      this.site_id = get(value, 'site.id')
      this.end_date = get(value, 'end_date')
      this.start_date = get(value, 'start_date')
      this.payment_ref = get(value, 'payment_ref')
      this.online_direct = get(value, 'online_direct')
      this.customer_id = get(value, 'site.customer.id')
      this.term_requested = get(value, 'term_requested')
      this.term_applied_at = get(value, 'term_applied_at')
      this.term_accepted_at = get(value, 'term_accepted_at')
      this.deal_closed_at = get(value, 'deal_closed_at')
      this.deal_closed_by_id = get(value, 'deal_closed_by.id')
      this.lead_generated_by_id = get(value, 'lead_generator.id')

      this.documents = get(value, 'files', [])
      this.meters = map(get(value, 'meters'), meter => {
        const [type, mprn, spid, topLine, mpanCore] = ['meter.type', 'meter.mprn', 'meter.spid', 'meter.top_line', 'meter.mpan_core'].map(i => get(meter, i))

        return {
          ...meter,
          ref: type === 'electric'
            ? `${[topLine?.slice(0, 2), topLine?.slice(2, 5), topLine?.slice(5, 8)].join(' ')}${topLine ? ' / ' : ''}${[mpanCore?.slice(0, 2), mpanCore?.slice(2, 6), mpanCore?.slice(6, 10), mpanCore?.slice(10, 13)].join(' ')}`
            : (type === 'gas' ? mprn : spid),
          scUplift: meter.sc_uplift
        }
      })

      this.selectedSite = get(value, 'site')
      this.selectedCustomer = get(value, 'site.customer')
      this.selectedClosedBy = get(value, 'deal_closed_by')
      this.selectedLeadGenerator = get(value, 'lead_generator')
    },
    meters () {
      if (!this.start_date) {
        const ced = this.meters[0] && this.meters[0].meter && this.meters[0].meter.original_end_date
        this.start_date = ced ? this.moment(ced).add(1, 'day').toDate() : this.start_date
      }
    }
  }
}
</script>
